body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html{
  overflow-y: scroll;
}


.success-msg {
  color: #57A773;
  padding: 10px 15px;
}


.err-msg {
  color: #EE6352;
  padding: 10px 15px;
}