.container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  top: 60px;
   color: white;
  margin-bottom:200px;
  box-shadow: 0 3px 5px 2px #a8abab;
  height: 600px;
 background: linear-gradient(0deg, #78c7c1 0%, #3e8599 50%, #1b5d7f 100%);
}


