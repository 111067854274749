Nav {

    mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 25%, #ffffff 75%, rgba(255, 255, 255, 0) 100%);
    margin-left: auto;
    margin-right: auto;

}

Nav ul {
    text-align: center;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2)
    25%, rgba(255, 255, 255, 0.2) 75%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
}

Nav ul li {
    display: inline-block;
}

Nav ul li a {
    padding: 10px;
    font-family: Times New Roman;
    text-transform: uppercase;
    color: black;
    font-size: 19px;
    text-decoration: none;
    display: block;
}

Nav ul li a:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.1);
    color: rgba(0, 35, 122, 0.7);

}

body {
    font-family: 'Open Sans';
    overflow: hidden;
}